import React from 'react';
import { Link } from 'gatsby';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-1/4 mr-3">
          <LogoIcon />
        </div>
      </div>

      <div className="flex mt-4 sm:mt-0">
        <Link to={'/'} className="px-4">
          Home
        </Link>
        <Link to={'/RevOps'} className="px-4">
          RevOps
        </Link>

        {/* <Link to={'/RevOps'} className="px-4">
         Analytics
        </Link> */}

        {/* <AnchorLink className="px-4" href="#services">
          Services
        </AnchorLink>
        <AnchorLink className="px-4" href="#about">
          About
        </AnchorLink>
        <AnchorLink className="px-4" href="#contact">
          contact
        </AnchorLink>
        <AnchorLink className="px-4" href="#more">
          More
        </AnchorLink> */}
      </div>
      <div className="hidden md:block">
        <a href="https://app.cognizance.vision/login" target="_blank">
          <Button className="text-sm">Login</Button>
        </a>
      </div>
    </div>
  </header>
);

export default Header;
