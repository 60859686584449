import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800 bg-gray-100">
    <div className="flex mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">Cognizance, the insights to action platform.</p>
        <h2 className="mt-5 text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.linkedin.com/company/cognizancevision">LinkedIn</a>
          </li>
          <li>
            <a href="https://pages.facebook.com/Cognizance.vision/">Facebook</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
